<template>
  <b-navbar wrapper-class="container">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="../assets/iec-karte-logo.svg" class="brand-logo" />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" to="/">
        <b-icon
          pack="fas"
          icon="qrcode"
          class="mr-1"
        />Meine&nbsp;<iec-card-logo :plural="true" />
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/login"
        v-if="$store.getters.user != null"
      >
        Abmelden
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/login"
        v-if="$store.getters.user == null"
      >
        <b-button>Anmelden</b-button>
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item
        tag="a"
        href="https://plugandyay.de"
        class="is-hidden-mobile"
      >
        <img
          src="https://ticketingsolutions.canto.global/direct/image/cg34ect7oh16lbf6iougbdnd5b/E_uBGzuZJqmfv--oxRp9o4J6RGE/m320/800"
          style="height: 5rem; max-height: 5rem"
        />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import IecCardLogo from "./IecCardLogo.vue";
export default {
  name: "BaseHeader",
  components: { IecCardLogo },
};
</script>

<style>
@media only screen and (min-width: 768px) {
  .navbar-brand {
    background: none;
  }

  .brand-logo {
    width: 10rem;
    display: block;
  }

  .navbar .navbar-brand {
    min-height: 5rem !important;
  }
}
</style>
