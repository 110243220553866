<template>
  <footer class="footer">
    <div class="content container px-5">
      <div class="columns">
        <div class="column has-text-centered-mobile">
          <a
            href="https://www.facebook.com/ticketingsolutions"
            class="button"
            target="_blank"
            ><b-icon pack="fab" icon="facebook" size="is-medium"
          /></a>
          <a
            href="https://twitter.com/plugandyay"
            class="button ml-2"
            target="_blank"
            ><b-icon pack="fab" icon="twitter" size="is-medium"
          /></a>
          <a
            href="https://www.instagram.com/plugandyay"
            class="button ml-2"
            target="_blank"
            ><b-icon pack="fab" icon="instagram" size="is-medium"
          /></a>
          <a
            href="https://www.linkedin.com/company/elevayte"
            class="button ml-2"
            target="_blank"
            ><b-icon pack="fab" icon="linkedin" size="is-medium"
          /></a>
        </div>
        <div class="column has-text-centered">
          <p>
            <router-link to="/privacy">Datenschutz</router-link> |
            <router-link to="/imprint">Impressum</router-link>
          </p>
        </div>
        <div class="column has-text-centered-mobile has-text-right">
          <p>&copy; 2024 PLUG&YAY GmbH</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "BaseHeader",
  components: {},
};
</script>
